import bgImage from "./public/images/fond/bg.png"
import "./App.css"
import Navbar from "./Components/Applications/navbar"
import About from "./Components/home/About"
import Intro from "./Components/home/Intro"
import Projects from "./Components/home/Projects"
import Footer from "./Components/Applications/Footer"
import Contact from "./Components/home/Contact"
import { ModalProvider } from "./Components/home/ModalContext"
const divStyle = {
	minHeight: "100%",
	minWidth: "100%",

	backgroundImage: `url(${bgImage})`,
	backgroundSize: "cover", // <---- This is important
	backgroundPosition: "center",
	// position: "fixed",
	backgroundrRepeat: "no-repeat",
}

function App() {
	return (
		<div id="home-page">
			<Navbar />

			<div
				id="intro"
				className="h-[100vh] ml-[8%] mr-[8%] sm:ml-[15%] sm:mr-[15%] flex justify-center items-center text-center relative overflow-hidden"
			>
				<Intro />
			</div>

			<div id="about" className="md:flex justify-center items-center">
				<About />
			</div>
			{/* <About /> */}
			{/* <About /> */}
			{/* mt-36 */}
			<div
				id="my-projects"
				className="projects flex flex-col justify-center items-center mt-[3.75rem] "
			>
				<ModalProvider>
					<Projects />
				</ModalProvider>
			</div>

			<div id="contact-me">
				<Contact />
			</div>

			<footer
				className="bg-white"
				style={{
					boxShadow: "0px -3px 6px #00000029",
				}}
			>
				<Footer />
			</footer>
		</div>
	)
}

export default App
