import React, { Suspense } from "react"
import { useTranslation } from "react-i18next"
import "./home.css"
// const french = {
// 	title: "Qui suis-je ?",
// 	roadmap: {
// 		title: "Mon parcours",
// 		content:
// 			"Après une licence en Géographie et Aménagement obtenue à l'université Paris 1 Panthéon Sorbonne, j'ai obtenU un master en Urbanisme et Aménagement à l'École d'Urbanisme de Paris. Durant mon parcours, j'ai développé de solides compétences en cartographie et urbanisme. Je prépare actuellement un projet de thèse portant sur la conservation du patrimoine urbain au Moyen-Orient.",
// 	},
// 	skills: {
// 		title: "Mes compétences",
// 		urbanisme: {
// 			name: `Urbanisme`,
// 			content: `Diagnostic territorial et spatial, analyse d'acteurs, réalisation de benchmark, mise en place de méthodologies scientifiques (observations, entretiens).`,
// 		},
// 		cartographie: {
// 			name: "Cartographie",
// 			content: `Cartes d'édition (historiques, géopolitiques, tourisme, atlas), d'urbanisme (plan d'urbanisme, carte de localisation), statistiques (univariée et bivariée), datavizualisation`,
// 		},
// 	},
// }
const english = { test: "english" }

// i18n.use(initReactI18next).init({
// 	resources: {
// 		en: { translation: english },
// 		fr: { translation: french },
// 	},
// 	lng: "fr",
// 	fallbackLng: "fr",
// 	interpolation: { escapeValue: false },
// })
const sectionTitlesClass =
	"mb-7 lg:mb-0 self-start  tracking-[0.50px]  font-semibold   md:basis-2/5    lg:basis-1/4 lg:basis-[30%]  tracking-wider font-argentum-regular text-[16px] md:text-[19px]"

const sectionContentClass =
	"md:basis-1/2 lg:basis-1/2 xl:basis-[40%] font-argentum text-[16px] md:text-[19px] md:tracking-[0.44px] md:leading-[33px]"

function About() {
	const { t } = useTranslation("translation", { keyPrefix: "about" })

	return (
		<div id="about__" className=" px-2 py-1 sm:px-4 lg:px-8">
			<div className="md:flex md:justify-end md:items-center md:flex-row-reverse md:justify-items-start px-6 md:pl-28 ">
				<div className="text-start  md:ml-4 pb-[5px] md:pb-0 text-[20px]  md:text-[30px] md:tracking-[0.92px] font-semibold font-merriweather">
					{t("title")}
				</div>
				<div className="flex justify-center items-center item h-[3px] text-center bg-green-800 w-[100%] md:w-[20%] lg:w-[239px]"></div>
			</div>
			{/* <p>{t("roadmap.name")}</p> */}
			{/* <Trans></Trans> */}
			<div className="mt-14 lg:flex lg:justify-start px-6 md:pl-28">
				{/* mon parcours */}
				<div className={sectionTitlesClass}>{t("roadmap.title")}</div>
				<div className={sectionContentClass}>{t("roadmap.content")}</div>
			</div>

			<div className="mt-14 lg:flex flex-col  items-center justify-start md:flex-row lg:justify-start px-6 md:pl-28">
				{/* mes compétences*/}
				<div className={sectionTitlesClass}>{t("skills.title")}</div>
				{/* w-[80%] */}
				{/* <div className="my-skills  md:mr-[10%] md:basis-1/2  xl:basis-1/4 font-argentum text-[22px]"> */}
				<div className={sectionContentClass}>
					<div className="">
						{t("skills.urbanisme.name")}: {t("skills.urbanisme.content")}
					</div>
					<div className="pt-5 ">
						{t("skills.cartographie.name")}: {t("skills.cartographie.content")}
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
