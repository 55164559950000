import React from "react"
import { Trans, useTranslation } from "react-i18next"
import "./home.css"
import { scrollSmooth } from "../../lib/scroll"

export default function Intro() {
	const { t } = useTranslation("translation", { keyPrefix: "intro" })
	return (
		<div className="mt-[-100px] md:mt-[-100px] md:w-[500px] lg:w-[770px]">
			{/* INTRODUCTION */}
			<div className="text-[25px] lg:text-[40px] leading-8 tracking-[1.4px] lg:tracking-[2.24px] lg:leading-[3.5rem] font-black  md:font-bold lg:w-[770px] md:text-center    font-merriweather  md:flex md:justify-center ">
				<div className="text-center">{t("introduction")}</div>
			</div>

			{/* <div className="mt-6 md:mt-9 font-argentum text-[16pt] md:text-[20px]"> */}
			<div className="mt-6 md:mt-9 argentum-lt text-[16px] md:text-[20px]">
				<Trans
					components={{
						bold: <b className="font-argentumBold" />,
					}}
				>
					intro.presentation
				</Trans>
				{/* {t("presentation")} */}
			</div>
			<div className="font-argentumLight text-[16px] md:text-[20px]">
				{t("proposition")}
			</div>
			<a
				href="#contact-me"
				onClick={(e) => scrollSmooth(e, "contact-me")}
				className="mt-12 md:mt-[71px] rounded-full inline-flex justify-center items-center px-9 py-1 border border-orange-600 shadow-sm text-[14px] md:text-[20px] font-normal  text-white hover:font-bold bg-[#d9310b] text-center hover:block hover:mr-auto hover:ml-auto w-[250px] focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-500 font-argentum hover:font-argentumBold"
			>
				{t("button")}
			</a>
		</div>
	)
}
