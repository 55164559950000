import React, { Suspense, lazy } from "react"

import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import alep from "../../public/images/cards/alep.jpg"

import goreeSituation from "../../public/images/cards/goreeSituation.jpg"
import naplouse from "../../public/images/cards/naplouse.jpg"
import mongolie from "../../public/images/cards/mongolie.jpg"
import cachemire from "../../public/images/cards/cachemire.jpg"
// schemesHistoryBook : il y a deux
import schemesHistoryBook1 from "../../public/images/cards/Nathan-02.jpg"
import schemesHistoryBook2 from "../../public/images/cards/Nathan-03.jpg"
import saintMartin from "../../public/images/cards/saint-martin-remastered.jpg"

import election2017 from "../../public/images/cards/election2017.jpg"

// goree 2 financement AFD
import dakarPisteCyclable1 from "../../public/images/cards/dakar-cyclable-1.jpg"
import dakarPisteCyclable2 from "../../public/images/cards/dakar-cyclable-2.jpg"
import { useTranslation } from "react-i18next"
import Modal from "./Modal"
import { useModal } from "./ModalContext"

const cards = {
	alep: [alep],
	goreeSituation: [goreeSituation],
	naplouse: [naplouse],
	dakarPisteCyclable: [dakarPisteCyclable1, dakarPisteCyclable2],
	election2017: [election2017],
	diagnosticMongolie: [mongolie],
	cachemireSituation: [cachemire],
	schemesHistoryBook: [schemesHistoryBook1, schemesHistoryBook2],
	saintMartin: [saintMartin],
	// saintMartin: [OtherComponent],
}

const Projects = () => {
	const { open, setOpen } = useModal()
	const { t } = useTranslation("translation", { keyPrefix: "projects" })
	return (
		<div className="mt-10 px-2 py-1 sm:px-4 lg:px-8 ">
			<div className="md:flex md:justify-end md:items-center md:flex-row-reverse md:justify-items-start px-6 md:pl-28">
				<div className="text-start md:ml-4 text-[20px] md:text-[30px] pb-[5px] md:pb-0 md:tracking-[0.92px] font-semibold font-merriweather">
					{/* Mes Projets */}
					{t("title")}
				</div>
				<div className="flex justify-center items-center item h-[3px] text-center bg-project-title w-[100%] md:w-[20%] lg:w-[239px]"></div>
			</div>
			<div className="flex flex-col justify-center items-center mt-16 px-6 lg:px-0">
				{/* {Object.keys(cards)} */}
				{/* {cards &&
					Object.keys(cards)?.map((cardKey) => (
						<React.Fragment key={cardKey}>
							<Card images={cards[cardKey]} cardKey={cardKey} />
						</React.Fragment>
					))} */}
				{/* alep: [alep], */}
				<Card images={cards.alep} cardKey={"alep"} lazyLoad={false} />
				{/* goreeSituation: [goreeSituation], */}
				<Card
					images={cards.goreeSituation}
					cardKey={"goreeSituation"}
					lazyLoad={false}
				/>
				{/* naplouse: [naplouse], */}
				<Card images={cards.naplouse} cardKey={"naplouse"} lazyLoad={false} />
				{/* dakarPisteCyclable: [dakarPisteCyclable1, dakarPisteCyclable2], */}
				<Card
					images={cards.dakarPisteCyclable}
					cardKey={"dakarPisteCyclable"}
					lazyLoad={false}
				/>

				{/* election2017: [election2017], */}
				<Card
					images={cards.election2017}
					cardKey={"election2017"}
					lazyLoad={false}
				/>

				{/* diagnosticMongolie: [mongolie], */}
				<Card
					images={cards.diagnosticMongolie}
					cardKey={"diagnosticMongolie"}
					lazyLoad={false}
				/>

				{/* cachemireSituation: [cachemire], */}
				<Card
					images={cards.cachemireSituation}
					cardKey={"cachemireSituation"}
					lazyLoad={false}
				/>

				{/* schemesHistoryBook: [schemesHistoryBook1, schemesHistoryBook2], */}
				<Card
					images={cards.schemesHistoryBook}
					cardKey={"schemesHistoryBook"}
					lazyLoad={false}
				/>

				{/* saintMartin: [saintMartin], */}
				<Card
					images={cards.saintMartin}
					cardKey={"saintMartin"}
					lazyLoad={true}
				/>
			</div>
			<Modal />
		</div>
	)
}
const imageStyle = {
	height: "auto",
	width: "auto",
	cursor: "pointer",
}

/**
 *
 * @Component {*} Image
 * @param {*} images
 * @returns image, loop through images if multiple
 */

const isLastImage = (idx, images) => idx === images.length - 1

const Image = ({ image, idx, images, lazyLoad }) => {
	const { setOpen, setImageUrl } = useModal()
	const onClickImage = (imageSrc) => {
		setOpen(true)
		setImageUrl(imageSrc)
	}

	if (lazyLoad === true) {
		console.log({ lazy: image })
		return (
			<LazyLoadImage
				onClick={() => onClickImage(image)}
				src={image}
				srcSet={image}
				alt={"project"}
				style={imageStyle}
				className="mb-[11.75px] lg:min-w-[10%]"

				// loading="lazy"
				// className="px-28"
			/>
		)
	}

	return (
		<>
			{image && isLastImage(idx, images) ? (
				<>
					<img
						onClick={() => onClickImage(image)}
						src={image}
						srcSet={image}
						alt={"project"}
						style={imageStyle}
						className="mb-[11.75px] lg:min-w-[10%]"

						// loading="lazy"
						// className="px-28"
					/>
				</>
			) : (
				<img
					src={image}
					srcSet={image}
					alt={"project"}
					className="mb-[11.75px] lg:min-w-[10%]"
					onClick={() => onClickImage(image)}
					style={imageStyle}
					// loading="lazy"
				/>
			)}
		</>
	)
}

const Card = ({ images, cardKey, lazyLoad }) => {
	console.log({ images, cardKey })

	const { t } = useTranslation("translation", { keyPrefix: "projects" })

	return (
		<>
			<div className="mb-[50px] md:mb-16   md:w-[70%]  lg:w-[90%] xl:w-[85%] 2xl:w-[65%] lg:px-28">
				{images && images.length === 1 ? (
					<React.Fragment key={images[0]}>
						<Image
							idx={images[0]}
							images={images}
							image={images[0]}
							lazyLoad={lazyLoad}
						/>
					</React.Fragment>
				) : (
					images?.map((image, idx) => (
						<React.Fragment key={image}>
							<Image idx={idx} images={images} image={image} />
						</React.Fragment>
					))
				)}
				{cardKey && (
					<div className="md:pt-5 pb-2.5">
						<div className="font-merriweather font-normal  md:font-merriweather text-[17px] md:text-2xl md:tracking-[0.25px] md:font-bold ">
							{t(`${cardKey}.title`)}
						</div>
						<div className="text-[14px] md:text-[19px] italic font-thin font-argentum-lt-italic">
							{t(`${cardKey}.subtitle`)}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

// const CardWrapper = ({images}) => {
// 	const { t } = useTranslation("translation", { keyPrefix: "projects" })

// 	return (

// 	)
// }

export default Projects
