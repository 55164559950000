import React from "react"
import { useTranslation } from "react-i18next"

export default function Contact() {
	const { t } = useTranslation("translation", { keyPrefix: "contact" })
	return (
		<div className="px-2 py-1 sm:px-4 lg:px-8 mb-36 md:mb-64">
			<div className="md:flex md:justify-end md:items-center md:flex-row-reverse md:justify-items-start px-6 md:pl-28">
				<div className="text-start md:ml-4 pb-[5px] md:pb-0 text-[20px] md:text-[30px] md:tracking-[0.92px] font-semibold font-merriweather">
					{t("title")}
				</div>
				<div className="flex justify-center items-center item h-[3px] text-center bg-[#D29646] w-[100%] md:w-[20%] lg:w-[239px]"></div>
			</div>

			{/* <div className="flex justify-center  md:flex md:justify-center md:items-center   py-20"> */}
			<div className="md:flex md:justify-end md:items-center md:flex-row-reverse md:justify-items-start px-6 md:pl-28 py-20">
				<a
					className="flex justify-center text-email-small text-start font-bold underline   text-[16px] md:text-[25px] tracking-wide md:tracking-[0.92px] font-argentum-medium focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-50"
					href="mailto:ibrahim.bacha.ryan@protonmail.com"
					target="_blank"
					rel="noreferrer"
				>
					ibrahim.bacha.ryan@protonmail.com
				</a>
			</div>
			{/* <EmailForm t={t} /> */}
			{/* <ContactForm /> */}
		</div>
	)
}

// const inputClass =
// 	"bg-gray-100 py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
// function EmailForm({ t }) {
// 	const [email, setEmail] = React.useState("")
// 	const [content, setContent] = React.useState("")

// 	const [emailSent, setEmailSent] = React.useState(false)
// 	const [emailError, setEmailError] = React.useState(false)

// 	const onSubmit = (e) => {
// 		e.preventDefault()
// 		const action = "https://formsubmit.co/ajax/jiad@yopmail.com"
// 		fetch(action, {
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json",
// 				Accept: "application/json",
// 			},
// 			body: JSON.stringify({
// 				content: content,
// 				email: email,
// 			}),
// 		})
// 			.then((res) => {
// 				console.log({ res })
// 				if (res.ok) {
// 					return res.json()
// 				} else {
// 					console.log("not ok")
// 					setEmailError("email not sent")
// 				}
// 				// return res.json()
// 			})
// 			// .then((data) => console.log({ data }))
// 			.catch((error) => setEmailError("email not sent"))
// 	}
// 	return (
// 		<div>
// 			<form
// 				action="https://formsubmit.co/jiad@yopmail.com"
// 				method="POST"
// 				onSubmit={onSubmit}
// 			>
// 				<input type="hidden" name="_captcha" value="false" />

// 				<input
// 					type="text"
// 					onChange={(e) => setContent(e.target.value)}
// 					name="content"
// 					value={content}
// 					required
// 					placeholder="text"
// 				/>
// 				<input
// 					type="email"
// 					onChange={(e) => setEmail(e.target.value)}
// 					value={email}
// 					name="email"
// 					required
// 					placeholder="email"
// 				/>
// 				<button type="submit">Send</button>
// 			</form>
// 		</div>
// 	)
// }

// function ContactForm() {
// 	const { t } = useTranslation("translation", { keyPrefix: "contact" })
//   // const [firstName, setFirstName ]
// 	return (
// 		<div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 bg-white">
// 			<form
// 				action="#"
// 				method="POST"
// 				className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
// 			>
// 				<div>
// 					<label
// 						htmlFor="first-name"
// 						className="block text-sm font-medium text-warm-gray-900"
// 					>
// 						{t("form.firstName")}
// 					</label>
// 					<div className="mt-1">
// 						<input
// 							type="text"
// 							name="first-name"
// 							id="first-name"
// 							autoComplete="given-name"
// 							className={inputClass}
// 						/>
// 					</div>
// 				</div>
// 				<div>
// 					<label
// 						htmlFor="last-name"
// 						className="block text-sm font-medium text-warm-gray-900"
// 					>
// 						{t("form.lastName")}
// 					</label>
// 					<div className="mt-1">
// 						<input
// 							type="text"
// 							name="last-name"
// 							id="last-name"
// 							autoComplete="family-name"
// 							className={inputClass}
// 						/>
// 					</div>
// 				</div>
// 				<div>
// 					<label
// 						htmlFor="email"
// 						className="block text-sm font-medium text-warm-gray-900"
// 					>
// 						{t("form.email")}
// 					</label>
// 					<div className="mt-1">
// 						<input
// 							id="email"
// 							name="email"
// 							type="email"
// 							autoComplete="email"
// 							className={inputClass}
// 						/>
// 					</div>
// 				</div>
// 				<div>
// 					<div className="flex justify-between">
// 						<label
// 							htmlFor="phone"
// 							className="block text-sm font-medium text-warm-gray-900"
// 						>
// 							{t("form.phone")}
// 						</label>
// 						<span id="phone-optional" className="text-sm text-warm-gray-500">
// 							{t("form.optional")}
// 						</span>
// 					</div>
// 					<div className="mt-1">
// 						<input
// 							type="text"
// 							name="phone"
// 							id="phone"
// 							autoComplete="tel"
// 							className={inputClass}
// 							aria-describedby="phone-optional"
// 						/>
// 					</div>
// 				</div>
// 				<div className="sm:col-span-2">
// 					<label
// 						htmlFor="subject"
// 						className="block text-sm font-medium text-warm-gray-900"
// 					>
// 						{t("form.subject")}
// 					</label>
// 					<div className="mt-1">
// 						<input
// 							type="text"
// 							name="subject"
// 							id="subject"
// 							className={inputClass}
// 						/>
// 					</div>
// 				</div>
// 				<div className="sm:col-span-2">
// 					<div className="flex justify-between">
// 						<label
// 							htmlFor="message"
// 							className="block text-sm font-medium text-warm-gray-900"
// 						>
// 							{t("form.message")}
// 						</label>
// 						<span id="message-max" className="text-sm text-warm-gray-500">
// 							Max. 500 characters
// 						</span>
// 					</div>
// 					<div className="mt-1">
// 						<textarea
// 							id="message"
// 							name="message"
// 							rows={4}
// 							className="bg-gray-100 py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border border-warm-gray-300 rounded-md"
// 							aria-describedby="message-max"
// 							defaultValue={""}
// 						/>
// 					</div>
// 				</div>
// 				<div className="sm:col-span-2 sm:flex sm:justify-end">
// 					<button
// 						type="submit"
// 						className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
// 					>
// 						{t("form.submit")}
// 					</button>
// 				</div>
// 			</form>
// 		</div>
// 	)
// }
