/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import React, { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { SearchIcon } from "@heroicons/react/solid"
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline"
import { scrollSmooth } from "../../lib/scroll"
import Logo from "./../../public/images/logos/logo.png"
import LogoSvg from "./../../public/images/logos/logo.svg"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

export default function Navbar() {
	const [currentLang, setCurrentLang] = React.useState("FR")
	const [nextLang, setNextLang] = React.useState("EN")

	const changeLang = () => {
		if (currentLang === "FR") {
			setCurrentLang("EN")
			setNextLang("FR")
			return
		}
		if (currentLang === "EN") {
			setCurrentLang("FR")
			setNextLang("EN")
			return
		}
	}

	React.useEffect(() => {
		i18n.changeLanguage(currentLang.toLowerCase())
		console.log({ i18n, currentLang, nextLang })
	}, [currentLang, nextLang])

	return (
		<Disclosure as="nav" className="bg-white shadow-lg">
			<>
				<div className="px-2 md:py-3 sm:px-4 lg:px-8">
					<div className="flex justify-between h-[60px] md:h-16">
						<div className="flex px-6 md:pl-28">
							<div className="flex-shrink-0 flex items-center">
								<img
									className="block h-8 w-auto"
									src={LogoSvg}
									alt="Workflow"
								/>
							</div>
							{/* code here for left flex */}
						</div>
						<div className="flex items-center justify-center px-2 lg:ml-2 lg:justify-end">
							<Navigation
								open={true}
								changeLang={changeLang}
								nextLang={nextLang}
							/>
						</div>

						<div className="flex items-center lg:hidden px-4 ">
							{/* Mobile menu button */}
							<Disclosure.Button
								as="button"
								className="inline-flex items-center justify-center p-2  rounded-md text-gray-800 "
							>
								<span
									className="text-gray-800 font-argentum"
									onClick={changeLang}
								>
									{nextLang}
								</span>
							</Disclosure.Button>
						</div>
					</div>
				</div>
				{/* <Responsive /> */}
			</>
		</Disclosure>
	)
}

const navigationLinkClass =
	"border-transparent  text-gray-800  hover:text-gray-700 inline-flex items-center px-1 pt-1  text-sm font-medium font-argentum tracking-[0.34px] text-[15px] hover:font-[800]"

const Navigation = ({ open, changeLang, nextLang }) => {
	const [divClass, setDivClass] = React.useState("")
	const { t } = useTranslation()
	React.useEffect(() => {
		// open
		// ?
		setDivClass("sm:hidden hidden   lg:flex lg:space-x-3")
		// : setDivClass("hidden md:ml-6 lg:ml-6 lg:flex lg:space-x-8")
	}, [open])
	return (
		<div className={divClass}>
			{/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
			<a
				href="#about"
				onClick={(e) => scrollSmooth(e, "about")}
				className={
					"inline-flex  justify-center items-center py-1 text-[15px] font-normal  text-black hover:font-bold bg-white text-center  hover:mr-auto hover:ml-auto w-[90px] focus:outline-none  font-argentum hover:font-argentumSemiBold"
					// "inline-flex  justify-center items-center py-1 text-[15px] font-normal  text-black hover:font-bold bg-white text-center hover:block hover:mr-auto hover:ml-auto w-[90px] focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-50 font-argentum hover:font-argentumSemiBold"
				}
				// className={navigationLinkClass}
			>
				{t("footer.links.whoami")}
			</a>
			<a
				href="#my-projects"
				onClick={(e) => scrollSmooth(e, "my-projects")}
				// navigationLinkClass + " " + "hover:font-bold hover:block  w-[160px]"
				className={
					"inline-flex  justify-center items-center px-3 py-1 text-[15px] font-normal  text-black hover:font-bold bg-white text-center  hover:mr-auto hover:ml-auto w-[120px] focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-gray-50 font-argentum hover:font-argentumSemiBold"
				}
			>
				{t("footer.links.myProjects")}
			</a>

			<a
				href="#contact-me"
				onClick={(e) => scrollSmooth(e, "contact-me")}
				className="rounded-full inline-flex justify-center items-center px-3 py-1 border border-orange-600 shadow-sm text-base font-normal  text-[#d9310b] hover:font-[600] bg-white text-center hover:block hover:mr-auto hover:ml-auto w-[160px] focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-50 font-argentum hover:font-argentumSemiBold"
				// className="rounded-full inline-flex items-center px-3 py-1 border border-orange-600 shadow-sm text-base font-medium  text-[#d9310b] hover:text-orange-700 bg-white hover:font-[800] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-argentum"
			>
				{t("footer.links.contactMe")}
			</a>

			<button
				onClick={changeLang}
				className="inline-flex items-center px-3 py-1   text-base font-medium  bg-white border-transparent border-b-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 text-gray-800 font-argentum"
			>
				{nextLang}
			</button>
		</div>
	)
}

const Responsive = () => {
	return (
		<Disclosure.Panel className="lg:hidden">
			<div className="pt-2 pb-3 space-y-1">
				{/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
				<Disclosure.Button
					as="a"
					href="#"
					className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
				>
					Dashboard
				</Disclosure.Button>
				<Disclosure.Button
					as="a"
					href="#"
					className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
				>
					Team
				</Disclosure.Button>
				<Disclosure.Button
					as="a"
					href="#"
					className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
				>
					Projects
				</Disclosure.Button>
				<Disclosure.Button
					as="a"
					href="#"
					className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
				>
					Calendar
				</Disclosure.Button>
			</div>
		</Disclosure.Panel>
	)
}
