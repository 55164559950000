import React from "react"
import { useTranslation } from "react-i18next"
import Logo from "../../public/images/logos/logo.png"
import LogoSvg from "./../../public/images/logos/logo.svg"

import { scrollSmooth } from "./../../lib/scroll"

const linkClass =
	"focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-50 font-argentum"
export default function Footer() {
	const { t } = useTranslation("translation", { keyPrefix: "footer" })
	return (
		<div className="flex flex-col px-10 py-5 md:px-52">
			<div className="flex-shrink-0 flex items-center justify-end">
				<img
					src={LogoSvg}
					alt="logo Ryan Ibrahim-Bacha"
					srcSet={LogoSvg}
					className="block h-8 w-auto"
				/>
			</div>
			<div className="flex flex-col font-argentum text-ellipsis gap-[17px] tracking-wide w-fit text-[14px] md:text-[15px]">
				{/* <a href="#about">{t("links.whoami")}</a> */}
				<a
					href="#about"
					className={linkClass}
					onClick={(e) => scrollSmooth(e, "about")}
				>
					{t("links.whoami")}
				</a>
				<a
					href="#my-projects"
					className={linkClass}
					onClick={(e) => scrollSmooth(e, "my-projects")}
				>
					{t("links.myProjects")}
				</a>
				<a
					href="#contact-me"
					className={linkClass}
					onClick={(e) => scrollSmooth(e, "contact-me")}
				>
					{t("links.contactMe")}
				</a>
			</div>
			<div className="flex justify-end pt-9 font-merriweather text-[12px]">
				<p>{t("copyright")}</p>
			</div>
		</div>
	)
}
