/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

import { useModal } from "./ModalContext"

export default function Modal() {
	// const [open, setOpen] = useState(true)
	const { open, setOpen, imageUrl } = useModal()
	let refDiv = React.useRef(null)

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				initialFocus={refDiv}
				// className="fixed z-10 inset-0 overflow-y-auto"
				className="fixed z-10 inset-0 overflow-y-auto"
				onClose={setOpen}
			>
				<div className="flex items-center md:items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="absolute sm:fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg px-1 md:px-2 pt-0 md:pb-2 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:my-0 sm:align-middle md:max-w-screen-xl sm:p-6 md:py-1">
							<span
								className="flex justify-end cursor-pointer"
								onClick={() => setOpen(false)}
								ref={refDiv}
							>
								{<XIcon className="h-5 w-5 text-gray-500 pt-1" />}
							</span>
							<div>
								<div className="mt-0 mb-1 text-center sm:mt-5 md:my-0">
									<div className="mt-1 md:mt-2">
										<img src={imageUrl} alt={imageUrl} />
									</div>
								</div>
							</div>
							{/* <div className="mt-5 sm:mt-6"> */}
							{/* <button
									type="button"
									className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
									onClick={() => setOpen(false)}
								>
									Go back to dashboard
								</button> */}
							{/* </div> */}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
