import * as React from "react"

const ModalContext = React.createContext()

export function ModalProvider({ children }) {
	const [open, setOpen] = React.useState(false)
	const [imageUrl, setImageUrl] = React.useState(false)
	const value = { open, setOpen, imageUrl, setImageUrl }
	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export function useModal() {
	const context = React.useContext(ModalContext)
	if (!context) {
		throw new Error("useModal must be used within a ModalProvider")
	}
	return context
}
