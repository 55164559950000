import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationFR from "./public/locales/fr/translation.json"
import translationEN from "./public/locales/en/translation.json"

// the translations
const resources = {
	fr: {
		translation: translationFR,
	},
	en: {
		translation: translationEN,
	},
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "fr",
		fallbackLng: "fr",
		// keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
