import React from "react"
import ReactDOM from "react-dom"
import "./i18n"
import "./public/fonts/ArgentumSans-Light.otf"
import "./public/fonts/ArgentumSans-Regular.otf"
import "./public/fonts/ArgentumSans-Medium.otf"
import "./public/fonts/ArgentumSans-Bold.otf"
import "./public/fonts/ArgentumSans-SemiBold.otf"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
